import React, { useState } from 'react';
// import { withStyles } from "@material-ui/core/styles";


import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import HouseIcon from '@mui/icons-material/House';
import theme from './theme';
import MenuBar from './MenuBar';
import VerticalStepper from './VerticalStepper';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';

// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import CircleChecked from '@material-ui/icons/CheckCircleOutline';
// import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
// import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import FlagIcon from '@mui/icons-material/Flag';

import { 
  AppBar, 
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  FormLabel,
  FormGroup,
  Switch,
  Select,
  MenuItem,
  Toolbar, 
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Card,
  CardContent,
  Grid,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stepper,
  StepLabel,
  Step,
  Tab,
  Tabs,
  

  Table,
  TableRow,
  TableCell,
  TextField,
  TableFooter,
  Paper
} from '@mui/material';


import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';

import Logo from './Logo';
import Progress from './Progress';  
import StudentCard from './StudentCard';
import AccountCard from './AccountCard';
import Transactions from './Transactions';

const iconSize = 50;

const paymentAmount = "10,000.00"
const studentDetails = {
  name: "Callum Bedos",
  studentId: 123456789,
}

const paymentDetails = {
  payerName: "Callum Bedos",
  email: "me@email.com",
  phone: "(987) 654 3210",
  accountName: "Chase Bank",
  accountNumber: "*****6789",
  routingNumber: "987654321",
}


const BACKUPFooterContainer = styled.footer`  
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 1rem; 
  /*  Added above after the fact */

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px; /* TODO rem instead? */
  font-family: 'Montserrat', sans-serif; 
`;

const FooterContainer = styled.footer`
  float: 'right',
`;


// remove border on table cell

// const TableCellNoBorder = styled.TableCell`
//   border-bottom: "none";
// `;

const tableStyles = {
  border: "none",
}

const tableStylesPayments = {
  fontSize: "1rem",
  border: "none",
  "padding-right": "2rem",
  "padding-left": "1.5rem",
}

const tableStylesPaymentsFinalRow = {

  "padding-right": "2rem", // TODO: above copied here
  "padding-left": "1.5rem",

  // border: "1px solid #24305B", // doesnt work b/c border collapse overrides
  // borderRadius: "25px",
  // padding: '10px',
  border: "none",
  fontWeight: 'bold',
  color: "#033AFC",
  fontSize: "1rem",
  /*
  color: "pink",
  border: "1px solid #24305B",
  borderRadius: "25px",
  "border-radius": "25px",
  padding: '10px',
  color: 'red',
  fontWeight: "bold",
  fontColor: "red",
  */
}

const BackpackLogo = styled.img`
  height: 30px;
`;

function Footer() {

  return (
    // <FooterContainer styles={{ display: 'flex', flex: '1', alignItems: 'right',  right: '10px', position: 'absolute', bottom: '10px'}}>
    // <FooterContainer >
      <Grid container style={{ display: 'flex', "justify-content": 'end', "padding-right": '3rem', "padding-bottom": '2rem'}}>
        <Grid item>
          <Typography>powered by
          </Typography>
        </Grid>
        <Grid item>
        <BackpackLogo src="./BackpackLogo_Primary_Stacked.png" />
        </Grid>
        <Grid item>
         {/*<BackpackLogo src="./BackpackLogo_Primary_Stacked.png" />*/}
        </Grid>
        <Grid item>
          <Typography>
            Backpack
          </Typography>
        </Grid>
      </Grid>

    
    // </FooterContainer>
  )
}

function PaymentAmount(props) {

  const { handleNext } = props;

  const paymentSummary = [{
    description: "Amount going to Lone Star College System",
    amount: "$10,000",
  }, {
    description: "(+) Transaction Fees (1.0%)",
    amount: "$100",
  }, {
    description: "(-) Fee Discount",
    amount: "($100)",
  }, {
    description: "Amount to be disbursed from your 529",
    amount: "$10,000",
    styleOverrides: {
      // border: "1px solid #00D062",
      // padding: "10px",
      // borderRadius: "25px",
      // color: "#00D062",
      // color: "red",
      
      // "border-margin": '2px',
      //fontColor: "#00D062",
    }
  }]

  
  return (
    <Box sx={containerSpacing}>
      <br></br>
      <Typography variant="h6" fontWeight="bold">Enter payment amount</Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <br/>
          <TextField sx={{ "padding-top": "0rem", width: '80%'}} id="outlined-basic" label='$10,000.00' disabled variant="outlined" />
        </Grid>
        <Grid item xs={7}>
          <Typography color="information">Enter the amount that you want Lone Star College System to receive. This will likely be the same amount that appears on the bill you want to pay.</Typography>
        </Grid>
      </Grid>

      <br/><br/>
      <Typography variant="h6" fontWeight="bold">Payment summary</Typography>
      <br/>
      <Table sx={{backgroundColor: "#F6F6F6"}}>
        {paymentSummary.map( (paymentDetail, index) => {
          const overrideStyles = (index === paymentSummary.length - 1) ? tableStylesPaymentsFinalRow : tableStylesPayments; 
          return (
            <TableRow >
              {/* <span style={{color: 'red'}}> */}
              <TableCell sx={overrideStyles}>
                {paymentDetail.description}
              </TableCell>
              <TableCell align="right" sx={overrideStyles}>
                {paymentDetail.amount}
              </TableCell>
              {/* </span> */}
            </TableRow>
            )
          }
        )}
      </Table>
      <ActionButtons handleNext={handleNext} />
      <br/>
    </Box>
  );
}

function TableCellNoOutline(text) {
  return (<TableCell sx={{border: "none"}}>{{text}}</TableCell>)
}

const tableCellStyles = {
  border: "none",
}

const containerSpacing = {
  // we have 0 at the bottom to make sure the footer
  // doesn't get pushed too far down. There is already
  // a lot of spacing around the bottom action buttons
  // so it won't be crammed
  // "margin": '2rem 2rem 0rem 2rem', 
  "margin-top": '1rem', 
  "padding": '2rem 2rem 0rem 2rem'
}

const states = ["TX"];
const statePlans = [
  {
    name: "Texas College Savings Plan",
    icon: "./TexasCollegeSavingsPlanQuality.png",
  },
  { name: "Texas LoneStar 529 Plan",
    icon: "./LoneStar529PlanQuality.png",
  },
]

function PaymentInformation(props) {
  const { handleNext } = props;
  return (
    <>
    <Box sx={containerSpacing}>
    <br></br>
      <Typography variant="h5" fontWeight="bold">Review the below information</Typography>
      <br/>
      <Table>
      
      <TableRow numCols={4}>
        <TableCell sx={tableCellStyles}>
          <Typography>Student Information</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={tableCellStyles}>Student</TableCell>
        <TableCell sx={tableCellStyles}>{studentDetails.name}</TableCell>
        <TableCell sx={tableCellStyles}>Student ID</TableCell>
        <TableCell sx={tableCellStyles}>{studentDetails.studentId}</TableCell>
      </TableRow>

      {/* Divider */}

      <br />
      <TableRow>
        <TableCell sx={tableCellStyles}>
          <Typography>Payer Information</Typography>
        </TableCell>
      </TableRow>

      <TableRow sx={{border: "none"}}>
        <TableCell sx={tableCellStyles}>Payer</TableCell>
        <TableCell sx={tableCellStyles}>{paymentDetails.payerName}</TableCell>
        <TableCell sx={tableCellStyles}>Linked Account</TableCell>
        <TableCell sx={tableCellStyles}>{paymentDetails.accountName}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={tableCellStyles}>Email</TableCell>
        <TableCell sx={tableCellStyles}>{paymentDetails.payerName}</TableCell>
        <TableCell sx={tableCellStyles}>Account #</TableCell>
        <TableCell sx={tableCellStyles}>{paymentDetails.accountNumber}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={tableCellStyles}>Phone Number</TableCell>
        <TableCell sx={tableCellStyles}>{paymentDetails.phone}</TableCell>
        <TableCell sx={tableCellStyles}>Routing Number</TableCell>
        <TableCell sx={tableCellStyles}>{paymentDetails.routingNumber}</TableCell>
      </TableRow>
    </Table>
    <br/>
    <Typography color="information"><i>To change any of the above Payer Information visit your Backpack Dashboard by logging in here.</i></Typography>
    <br/>
    
    
    <ActionButtons handleNext={handleNext} />
    </Box>
    
    </>
  );
}

const stateOptions = [
  {label: "TX", value: 1, disabled: false},
  {label: "CA", value: 1, disabled: true},
];


// for the dropdowns, these are the tiny tiny images
const TinyIcon = styled.img`
  height: 25px;
  padding-right: 10px;
`

function Plan529(props) {

  const { handleNext } = props;

  const [selectedState, setSelectedState] = React.useState(0);
  const [selectedPlan, setSelectedPlan] = React.useState("");
  
  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {

  const handleStateChange = (event) => {
    console.log("hadnling state change event", event);
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setSelectedState(value);
  };

  const handlePlanChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPlan(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Box sx={containerSpacing}>
      <Typography variant="h5" fontWeight="bold">Select your 529 Plan</Typography>

      <FormControl sx={{ m: 1, width: 600, mt: 3 }}>
        
      {/* <Table>
      <TableRow>
      <TableCell sx={tableStyles}> */}
      <Grid container>
      <Grid item>
      <InputLabel>State</InputLabel>
      <Select
          InputLabelProps={{ shrink: false }}
          sx={{width: '150px', "padding-right": 0}}
          // labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={selectedState}
          label="State"
          onChange={handleStateChange}
          renderValue={(value) => {
            if (value === 2) {
              return <><TinyIcon src="./StateFlagsTX.png" />TX</>;
            }
            // return <em>State</em>;
          }
        } >
          <MenuItem value={1} disabled>
            <TinyIcon src="./StateFlagsCA.png" />CA
          </MenuItem>
          <MenuItem value={2}>
            <TinyIcon src="./StateFlagsTX.png" />TX
          </MenuItem>
        </Select>

      {/* </TableCell>
      <TableCell sx={tableStyles}> */}

      </Grid>
      <Grid item>

      <Select
          displayEmpty
          sx={{ "padding-left": '0px'}}
          value={selectedPlan}
          onChange={handlePlanChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Search and select your 529 plan...</em>;
            }

            // TODO: hacky. clean this up when we productionize
            const index = (selected === statePlans[0].name) ? 0 : 1;
            return <><TinyIcon src={statePlans[index].icon} />{selected}</>;
          }}
          /*MenuProps={MenuProps}*/
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* <MenuItem disabled value="">
            <em>Placeholder</em>
          </MenuItem> */}
          {statePlans.map((statePlan) => (
            <MenuItem
              key={statePlan.name}
              value={statePlan.name}
              /*style={getStyles(name, personName, theme)}*/
            ><TinyIcon src={statePlan.icon}/>{statePlan.name}
              {/* <Table>
                <TableRow>
                  <TableCell sx={tableStyles}>
                    <TinyIcon src={statePlan.icon}/>
                  </TableCell>
                  <TableCell sx={tableStyles}>
                    {statePlan.name}
                  </TableCell>
                </TableRow>
              </Table> */}
            </MenuItem>
          ))}
        </Select>

        </Grid>
      </Grid>
      {/* </TableCell>
      </TableRow>
      </Table> */}
      </FormControl>

      <br /><br />
      <br /><br />
      <br /><br />
      
      <br /><br />
      <br /><br />
      <br /><br />

      <Box>
        <Typography> <span style={{ color: 'red' }}>Important:</span> Backpack <i>does not </i>initiate the disbursement from your 529 into your linked bank account. </Typography>
        <br/>
        <FormControlLabel
          control={
            <Checkbox color="action" />
          }
          label={<Typography color="information">Check this box to acknowledge you have initiated a disbursement of ${paymentAmount} into your linked bank account`</Typography>}
        />
        
      </Box>
      <ActionButtons handleNext={handleNext}/>
    </Box>
    
  );
}

const dividerStyles = { 
  padding: '7px', 
  marginBottom: '10px', 
  borderBottom: 1, 
  borderColor: 'divider'
};

function Checklist(props) {
  const { handleNext } = props;

  return (
    <Box sx={containerSpacing}>
      <Typography variant="h5" fontWeight="bold">Your checklist</Typography>
      <br/><br/>
      <FormControl>
        {/* <FormLabel id="demo-radio-buttons-group-label"></FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel value="female" control={<Checkbox />} label="I want Lone Star College to receive [ $10,000 ]" />
          <Box sx={dividerStyles}/>
          <FormControlLabel value="male" control={<Checkbox />} label="I withdrew [ $10,150 ] from my Texas College Savings Plan 529  " />
          <Box sx={dividerStyles}/>
          <FormControlLabel value="other" control={<Checkbox />} label="I made my 529 withdrawal into the same bank account I linked to Backpack" />
        </RadioGroup>
      </FormControl>
      <br/><br/>
      <br/><br/>
      <Typography>
      If everything above looks good to go to we can finish up the payment process!
      <br/><br/>

Once you click “Make Payment” button, Backpack will be on the lookout for your 529 funds. As soon as we see them hit your linked bank account, we will initiate the payment to Lone Star College. Nothing more for you to do! 
      </Typography>

      <ActionButtons handleNext={handleNext} />
    </Box>
  );
}


function Completion(props) {

  const { handleNext } = props;
  
  const [value, setValue] = useState(0);
  const trackingSteps = [
    {
      index: 0,
      label: "You disbursed funds from your 529",
      date: "Aug 23, 2023"
    },
    {
      index: 1,
      label: "Funds will land in your linked account",
      date: "Est. Aug 25, 2023"
    },
    {
      index: 2,
      label: "Backpack will initiate payment from your linked account",
      date: "Est. Aug 25, 2023"
    },
    {
      index: 3,
      label:  "Lone Star will receive your payment",
      date: "Est. Aug 27, 2023"
    }
  ]

  const detailSteps = [
    {
      index: 0,
      label1: "529 withdrawal",
      label2: "$10,150.00",
    },
    {
      index: 1,
      label1: "Payment to LSCS",
      label2: "$10,000.00",
    },
    {
      index: 2,
      label1: "Initiated Date",
      label2: "08/23/2023",
    },
    {
      index: 3,
      label1: "Est. Date of Arrival",
      label2: "08/27/2023",
    }
  ]

  const stepperStyles = {
    "& .MuiStepIcon-root": {
      color: "#F5F5F5",
      fontSize: "2.5rem",
    },
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#00D062",
      }
    },
    "& .MuiStepIcon-text": {
      fill: "#F5F5F5",
    },
    "& .MuiStepConnector-line": {
      // To make the line thicker
      // 'border-color': '#F5F5F5',
      'border-color': '#F5F5F5',
      'border-top-width': '15px',
      // 'border-bottom-width': '5px',
    },
    // width: '100%',
    // "border-radius": '5px',
    // stepper: {
    //   iconColor: 'green' // or logic to change color
    // }

  }

  const handleTabChange = (event, newValue) => {
    console.log("handleTabChange:", newValue);
    setValue(newValue);
  };

  return (

    <Box sx={containerSpacing}>
      <Typography variant="h4" fontWeight="bold">Way to go!</Typography>
      <br/><br/>
      <Typography>

      We have everything we need from you.

      <br/><br/>

      Once we detect your 529 disbursement in your linked account, we will automatically initiate your payment to 
      <strong> Lone Star College System.</strong>

      <br/><br/>

      We will notify you via email with updates at each step shown below.
      </Typography>


      <br></br>
      <br></br>

      <Tabs value={value} onChange={handleTabChange}  >
        <Tab label="Tracking" sx={{borderBottom: 1, borderColor: 'divider'}} />
        <Tab label="Details" sx={{borderBottom: 1, borderColor: 'divider'}} />
      </Tabs>

      <Box sx={{ paddingTop: '2rem' }}>
        {/* Tracking Tab */}
        {value === 0 && (
          <Stepper sx={stepperStyles} activeStep={0} alternativeLabel>
              {trackingSteps.map((trackingStep) => (
                
                <Step completed={trackingStep.index === 0} key={trackingStep.index}>
                  <Typography color="bright">
                    <StepLabel fontColor="alert">{trackingStep.label}</StepLabel>
                  </Typography>
                </Step>
              ))}
          </Stepper>
        )}
        {/* Details Tab */}
        {value === 1 && (
          <Table>        
            <TableRow>
              {detailSteps.map((detailStep) => (
                <TableCell align="center" sx={tableStyles}>
                  <Typography fontWeight="light" color="information">
                    {detailStep.label1}
                  </Typography>
                </TableCell>
                ))}
            </TableRow>
            <TableRow>
              {detailSteps.map((detailStep) => (
                <TableCell align="center" sx={tableStyles}>
                  <Typography variant="h5" fontWeight="bold" color="black">
                    {detailStep.label2}
                  </Typography>
                </TableCell>
                ))}
            </TableRow>
          </Table>
          )}
        </Box>

      <br/><br/>
      <Typography color="information">Go to your Backpack dashboard at any time to check on the status of your payment.</Typography>
      <ActionButtons handleNext={handleNext} finalStep={true} />
    </Box>
  );
}


function ActionButtons(props) {

  const { handleNext, finalStep = false} = props;
  return (<>
    <Grid container justifyContent="flex-end" style={{padding: '2rem 2rem 1rem 2rem'}}>
    <BackHandOutlinedIcon style={{ fontSize: iconSize }} color="action"/>
    <Button onClick={handleNext}>
      { finalStep ? (
        <HouseIcon style={{ fontSize: iconSize }} color="action" />
      ) : (
        <ArrowCircleRightIcon style={{ fontSize: iconSize }} color="action" />
      )
      }
    </Button>
  </Grid>
</>)
}



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



export default function App() {
  
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStepClick = (step) => {
    setActiveStep(step); 
  }

  const steps = [
    'Payment Amount',
    'Payment Information',
    '529 Instructions',
    'Complete Payment',
    'Payment Tracking',
  ];

  return (

    <ThemeProvider theme={theme}>
      

      {/* Header */}
      <Box sx={{ flexGrow: 1, margin: '1rem' }}>
        <AppBar elevation={0} color="transparent" position="static">
          <Toolbar sx={{ justifyContent: "space-between"}}>
            <img style={{height: '4rem'}} src={"./LoneStarCollegeSystemQuality.png"}/> 
            
            
            <Stack>
              <Typography variant="h5" textAlign="center" fontWeight="bold">
                Lone Star College System
              </Typography>
              <Typography variant="h6" fontWeight="light" textAlign="center" >
                529 payment portal
              </Typography>
            </Stack>
            <MenuBar />
          </Toolbar>
        </AppBar>
        

        <Grid container columns={12} >
          
            <Grid item xs={3}>

              <Stepper sx={{paddingTop: '3rem', paddingLeft: '2rem'}} activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    {/* <Button onClick={handleStepClick}> */}
                    <StepLabel onClick={() => handleStepClick(index)}><Typography color="primary.main" fontWeight="bold" variant="h7">{label}</Typography></StepLabel>
                    {/* </Button> */}
                  </Step>
                ))}
              </Stepper>
            </Grid>

          <Grid item xs={9}>
            {activeStep === 0 && <PaymentAmount handleNext={handleNext} />}
            {activeStep === 1 && <PaymentInformation handleNext={handleNext} />}  
            {activeStep === 2 && <Plan529 handleNext={handleNext} />}
            {activeStep === 3 && <Checklist handleNext={handleNext} />}
            {activeStep === 4 && <Completion handleNext={handleReset} />}

          </Grid>
        </Grid>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

// Header menu 
function HeaderMenu() {
  return (
    <Drawer anchor="right">
      <List>
        <ListItem button>
          <ListItemText>Dashboard</ListItemText>  
        </ListItem>
        <ListItem button>
          <ListItemText>Transactions</ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>Account</ListItemText>
        </ListItem>
      </List>
    </Drawer> 
  );
}
