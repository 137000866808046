import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // spacing: 8,
  components: {
    TableCell: {
      styleOverrides: {
        border: "outline"
      }
    },
    // suggested from here: https://github.com/mui/material-ui/issues/13840
    // in order to remove the 'shrink' label that blocks the top left
    // outline on an input field
    // UPDATE: did not work :(
    // MuiInputLabel: {
    //   styleOverrides: {
    //     outlined: {
    //       '&.MuiInputLabel-shrink': {
    //         transform: 'translate(0px, 0px) scale(0.75)',
    //       },
    //     },
    //   },
    // }
  },
  palette: {
    primary: {
      main: '#24305B'
    },

    bright: {
      main: "#00D062",
    },
    green: {
      main: "#00D062",
    },
    action: {
      main: "#033AFC",
    },
    red: {
      main: '#E13030'
    },
    light: "#F5F5F5",
    information: "#989696",
    alert: "#E13030",
  },
  root: {
    color: '#24305B',
  },
  typography: {
    allVariants: {
      color: '#24305B'
    },
    "color": '#24305B',
    "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

export default theme;